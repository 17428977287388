// material-ui
import { ThreeDots } from "react-loader-spinner";
import { Container } from "react-bootstrap";

const Loader = () => {
    return (
        <Container>
            <ThreeDots
                height="80"
                width="80"
                radius="9"
                color="#1a60b6"
                ariaLabel="three-dots-loading"
                // wrapperStyle
                // wrapperClass
            />
        </Container>
    );
};

export default Loader;
