import React from "react";

import "./Banner.css";

const Surgery = () => {
    return (
        <div className="hero-slide-right text-start">
            <div className="banner-animate">
                <img
                    src={"/static/services/surgery/background.png"}
                    alt=""
                    className="img-fluid"
                />
                <img
                    src={"/static/services/surgery/ludzik1.png"}
                    alt=""
                    className="img-fluid a5"
                />
                <img
                    src={"/static/services/surgery/ludzik2.png"}
                    alt=""
                    className="img-fluid a6"
                />
            </div>
        </div>
    );
};

export default Surgery;
