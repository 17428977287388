import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Services from "../../Pages/Home/Services/Services";

const Service = ({ title, services }) => {
    return (
        <section className="service-wrapper">
            <Container>
                <Row>
                    <Col sm={12}>
                        <div className="section-title text-center">
                            <h1>{title}</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    {services.map((treatment, index) => (
                        <Services key={treatment.id} treatment={treatment} />
                    ))}
                </Row>
            </Container>
        </section>
    );
};

export default Service;
