import React from "react";
import Appoinment from "../../../Pages/Home/Appoinment/Appoinment";
import Banner from "../../../layouts/dashboard/Banner/Banner";
import ContactUs from "../ContactUs/ContactUs";

const Contact = () => {
    return (
        <>
            <Banner title={"Skontaktuj się z nami"} nav={"Kontakt"} />
            <ContactUs />
            <div id="appointment">
                <Appoinment />
            </div>
        </>
    );
};

export default Contact;
