export const FeaturesData = [
    {
        id: "001",
        title: "Fachowa obsługa",
        description:
            "Zaufaj naszym lekarzom i doświadcz fachowej obsługi, która troszczy się o Twój pełny komfort zdrowia i dobre samopoczucie",
        img: "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature1.svg",
    },
    {
        id: "002",
        title: "Najnowsze preparaty",
        description:
            "Odkryj moc innowacyjnych preparatów medycznych - Twoje zdrowie zasługuje na najnowsze rozwiązania.",
        img: "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature2.svg",
    },
    {
        id: "003",
        title: "Przystępne ceny",
        description:
            "Przystępne ceny, bez kompromisów w jakości opieki medycznej - zdrowie dostępne dla każdego.",
        img: "https://raw.githubusercontent.com/saifulemon/images-for-all-project/main/Donto/Features/feature3.svg",
    },
];
