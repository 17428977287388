import React from "react";
import { useState } from "react";
import { Carousel } from "react-bootstrap";
import "./Banner.css";

const HomeCarousel = () => {
    const [index, setIndex] = useState(0);
    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Carousel activeIndex={index} onSelect={handleSelect} pause={false}>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={"/static/banner/banner1.jpg"}
                    alt="First slide"
                />
                {/* <Carousel.Caption className="caption rounded-3">
          <h3>Tytuł</h3>
          <p>Opis obrazka</p>
        </Carousel.Caption> */}
            </Carousel.Item>
            <Carousel.Item>
                <img
                    className="d-block w-100"
                    src={"/static/banner/banner2.jpg"}
                    alt="Second slide"
                />

                {/* <Carousel.Caption className="caption">
          <h3>Tytuł</h3>
          <p>Opis obrazka</p>
        </Carousel.Caption> */}
            </Carousel.Item>
        </Carousel>
    );
};

export default HomeCarousel;
