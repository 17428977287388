import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import Aesthetic from "./Aesthetic";
import Surgery from "./Surgery";
import "./Banner.css";
const Banner = ({ title, nav, type }) => {
    return (
        <section className="banner-all text-white">
            <Container>
                <Row className="align-items-center">
                    <Col md={6} lg={6} sm={12}>
                        <div className="section-title">
                            <h1>{title}</h1>
                        </div>
                        <div className="breadcrumb-nav">
                            <a
                                href="/"
                                className="text-decoration-none text-white"
                            >
                                Strona główna
                            </a>
                            <span
                                href="/"
                                className="text-decoration-none text-white ml-2"
                            >
                                {nav}
                            </span>
                        </div>
                    </Col>
                    <Col md={6} lg={6} sm={12}>
                        {type === "surgery" ? <Surgery /> : <Aesthetic />}
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Banner;
