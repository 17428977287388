import React from "react";
import Appoinment from "../../../Pages/Home/Appoinment/Appoinment";
import Banner from "../../../layouts/dashboard/Banner/Banner";
import Details from "../Details/Details";

const Dentist = () => {
    return (
        <>
            <Banner title={"O nas"} nav={"O nas"} />
            <Details />
            <Appoinment />
        </>
    );
};

export default Dentist;
