import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import "./Gallery.css";
import PhotoAlbum from "react-photo-album";

const Gallery = () => {
    const [images, setImages] = useState([]);

    useEffect(() => {
        // Fetch image data dynamically (replace with your API endpoint)
        axios.get("/api/home/gallery").then((response) => {
            setImages(
                response.data.map(({ picture, ...rest }) => ({
                    ...rest,
                    src: picture,
                }))
            );
        });
    }, []);

    // Calculate the column width based on the number of columns and container width
    const calculateColumnWidth = () => {
        const containerWidth = window.innerWidth; // You can adjust this as needed
        const minColumnWidth = 200; // Minimum column width in pixels
        const maxColumns = Math.floor(containerWidth / minColumnWidth);
        return `${100 / maxColumns}%`;
    };

    return (
        <section className="gallery-wrapper text-white">
            <Container>
                <Row>
                    <Col sm={12} className="text-center">
                        <div className="section-title">
                            <h1>Galeria</h1>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <PhotoAlbum layout="rows" photos={images} />
                </Row>
            </Container>
        </section>
    );
};

export default Gallery;
