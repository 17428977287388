// import React, { useEffect, useState } from "react";
// import { Navigate, useRoutes, useLocation } from "react-router-dom";
// import { useSelector, useDispatch } from "react-redux";

// import { checkAuthenticated } from "./redux/slices/Auth";
import React from "react";
import { useRoutes, Navigate } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard";

// pages
// import About from "./Pages/About/About/About";
import Approved from "./Pages/Approved/Approved";
import Contact from "./Pages/Contact/Contact/Contact";
import Dentist from "./Pages/Dentist/Denitst/Dentist";
import Home from "./Pages/Home/Home/Home.jsx";
import Login from "./Pages/Login/Login";
import NotFound from "./Pages/NotFound/NotFound";
import Service from "./Pages/Services/Service/Service";

// ----------------------------------------------------------------------

export default function Router() {
    // const { isAuthenticated, loading, user } = useSelector(
    //     (state) => state.auth
    // );
    // const [previousRoute, setPreviousRoute] = useState(null);
    // const dispatch = useDispatch();
    // const { pathname } = useLocation();

    // const authRoute = (Component) => {
    //     if (loading || pathname !== previousRoute) {
    //         return <Loader />;
    //     } else if (isAuthenticated) {
    //         return Component;
    //     }
    //     return <Navigate to="/" />;
    // };

    // const adminRoute = (Component) => {
    //     if (loading || pathname !== previousRoute) {
    //         return <Loader />;
    //     } else if (isAuthenticated && user && user.is_staff) {
    //         return Component;
    //     }
    //     return <Navigate to="/" />;
    // };
    // useEffect(() => {
    //     const verifyAuthentication = async () => {de
    //         if (previousRoute !== pathname) {
    //             setPreviousRoute(pathname);
    //             if (
    //                 !loading &&
    //                 (pathname.includes("/dashboard") ||
    //                     pathname.includes("/admin"))
    //             ) {
    //                 // check if pathname is the protected one to reduce request
    //                 try {
    //                     await dispatch(checkAuthenticated());
    //                 } catch (rejectedValueOrSerializedError) {}
    //             }
    //         }
    //     };
    //     verifyAuthentication();
    // }, [pathname, previousRoute, dispatch, loading]);

    return useRoutes([
        // {
        //     path: "/admin",
        //     element: adminRoute(<DashboardLayout />),
        //     children: [
        //         { path: "panel", element: <Admin /> },
        //         {
        //             path: "activate/:uid",
        //             element: <VerifyAccount />,
        //         },
        //     ],
        // },
        // {
        //     path: "/dashboard",
        //     element: authRoute(<DashboardLayout />),
        //     children: [
        //         { path: "", element: <StartingPage /> },
        //         { path: "blog", element: <Blog /> },
        //         {
        //             path: "blog/:slug",
        //             element: (
        //                 <ContentDetail
        //                     pageTitle="Blog"
        //                     endpoint="/api/content/blog/"
        //                 />
        //             ),
        //         },
        //         {
        //             path: "articles/:category/:type",
        //             element: <Articles />,
        //         },
        //         {
        //             path: "article/:slug",
        //             element: (
        //                 <ContentDetail
        //                     pageTitle="Article"
        //                     endpoint="/api/content/article/"
        //                 />
        //             ),
        //         },
        //         {
        //             path: "product/:slug",
        //             element: (
        //                 <ContentDetail
        //                     pageTitle="Product"
        //                     endpoint="/api/content/article/"
        //                 />
        //             ),
        //         },
        //         {
        //             path: "search/:searchPhrase",
        //             element: <Search />,
        //         },
        //         { path: "notifications", element: <NotificationsAll /> },
        //         { path: "profile", element: <Profile /> },
        //         { path: "videos", element: <Videos /> },
        //         { path: "contact", element: <Contact /> },
        //         { path: "license", element: <LicenseGenerator /> },
        //     ],
        // },
        // {
        //     path: "",
        //     element: !isAuthenticated ? (
        //         <LandingPage />
        //     ) : (
        //         <Navigate to="/dashboard" />
        //     ),
        // },
        // {
        //     path: "/",
        //     element: !isAuthenticated ? (
        //         <LogoOnlyLayout />
        //     ) : (
        //         <Navigate to="/dashboard" />
        //     ),
        //     children: [
        //         { path: "login", element: <Login /> },
        //         { path: "register", element: <Register /> },
        //         { path: "reset_password", element: <PasswordReset /> },
        //         {
        //             path: "password/reset/confirm/:uid/:token",
        //             element: <PasswordResetConfirm />,
        //         },
        //         { path: "404", element: <NotFound /> },
        //         { path: "/", element: <Navigate to="/login" /> },
        //         { path: "*", element: <Navigate to="/404" /> },
        //     ],
        // },
        { path: "admin/*", element: <Navigate to="/admin/" /> },
        {
            path: "/",
            element: <DashboardLayout />,
            children: [
                { path: "", element: <Home /> },
                { path: "home", element: <Home /> },
                { path: "about", element: <Dentist /> },
                { path: "service/:type", element: <Service /> },
                // { path: "dentist", element: <Dentist /> },
                { path: "contact", element: <Contact /> },
                { path: "approved", element: <Approved /> },
                { path: "login", element: <Login /> },
                { path: "*", element: <NotFound /> },
            ],
        },
    ]);
}
