import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect, useState } from "react";
import axios from "axios";
import { Col, Container, Row } from "react-bootstrap";
import "./Testimonial.css";

const Testimonial = () => {
    const [references, setReferences] = useState([]);
    const [loading, setLoading] = useState(false);
    const animationDelay = 5000;

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await axios.get("/api/home/references");
                setReferences(res.data);
            } catch (err) {
                // showErrorMessage(err);
            } finally {
                setLoading(false);
            }
        };
        const initAos = () => {
            AOS.init({
                duration: 2000,
            });
            AOS.refresh();
        };

        fetchData();
        initAos();
    }, []);
    return (
        <>
            {!loading && references.length > 0 && (
                <section className="testimonial-wrapper">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="section-title">
                                    <h1>Referencje</h1>
                                </div>
                            </Col>
                        </Row>
                        <>
                            {references.map((element, index) => (
                                <Row className="align-items-center" key={index}>
                                    <Col md={6} lg={4} sm={12}>
                                        <div
                                            className="client-box text-center"
                                            data-aos="fade-right"
                                            data-aos-delay={
                                                index * animationDelay
                                            }
                                        >
                                            {element.picture && (
                                                <div className="client-img">
                                                    <img
                                                        src={element.picture}
                                                        alt=""
                                                    />
                                                </div>
                                            )}
                                            <h3 className="mt-4">
                                                {element.name}
                                            </h3>
                                            <span>{element.proffesion}</span>
                                        </div>
                                    </Col>
                                    <Col md={6} lg={8} sm={12}>
                                        <div
                                            className="review-item text-start"
                                            data-aos="zoom-out"
                                            data-aos-delay={
                                                index * animationDelay + 500
                                            }
                                        >
                                            <h5>{element.heading}</h5>
                                            <p>{element.description}</p>
                                        </div>
                                    </Col>
                                </Row>
                            ))}
                        </>
                    </Container>
                </section>
            )}
        </>
    );
};

export default Testimonial;
