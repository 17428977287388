import React from "react";
import { useParams } from "react-router-dom";
import Appoinment from "../../../Pages/Home/Appoinment/Appoinment";
import Banner from "../../../layouts/dashboard/Banner/Banner";
import Detail from "../Detail/Detail";

const SERVICES = {
    surgery: { title: "Usługi chirurgii ogólnej", nav: "Chirurgia ogólna" },
    aesthetic: {
        title: "Usługi medycyny estetycznej",
        nav: "Medycyna estetyczna",
    },
};

const Service = () => {
    const params = useParams();
    const type = params.type;

    return (
        <>
            <Banner
                title={SERVICES[type].title}
                nav={SERVICES[type].nav}
                type={type}
            />
            <Detail type={type} />
            {/* <Appoinment /> */}
        </>
    );
};

export default Service;
