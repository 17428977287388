import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import Doctors from "../../Pages/Home/Doctors/Doctors";
import axios from "axios";

const Doctor = () => {
    const [doctors, setDoctors] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await axios.get("/api/doctors/list");
                setDoctors(res.data);
            } catch (err) {
                // showErrorMessage(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);
    return (
        <>
            {!loading && doctors.length > 0 && (
                <section className="doctor-wrapper">
                    <Container>
                        <Row>
                            <Col sm={12}>
                                <div className="section-title">
                                    <h1 className="mb-5 mb-sm-dent">
                                        Nasza załoga
                                    </h1>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-md-center">
                            {doctors.map((doctor) => (
                                <Doctors key={doctor.id} doctor={doctor} />
                            ))}
                        </Row>
                    </Container>
                </section>
            )}
        </>
    );
};

export default Doctor;
