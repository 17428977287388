import { faEnvelope, faHome, faPhone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./ContactUs.css";

const ContactUs = () => {
    return (
        <section className="contactUs-wrapper">
            <Container>
                <Row>
                    <Col md={6} lg={6} xl={4}>
                        <div className="single-contact icon1">
                            <div className="c-icon">
                                <FontAwesomeIcon icon={faHome} />
                            </div>
                            <div className="c-info text-start">
                                <h4>Adres</h4>
                                <p>Plac Ratuszowy 1</p>
                                <p>72-500 Międzyzdroje</p>
                            </div>
                        </div>
                    </Col>
                    <Col md={6} lg={6} xl={4}>
                        <a href="mailto:p.mizerny@wp.pl">
                            <div className="single-contact icon2">
                                <div className="c-icon">
                                    <FontAwesomeIcon icon={faEnvelope} />
                                </div>
                                <div className="c-info text-start">
                                    <h4>Email</h4>
                                    <p>p.mizerny@wp.pl</p>
                                </div>
                            </div>
                        </a>
                    </Col>
                    <Col md={6} lg={6} xl={4}>
                        <a href="tel:+48602190277">
                            <div className="single-contact icon3">
                                <div className="c-icon">
                                    <FontAwesomeIcon icon={faPhone} />
                                </div>
                                <div className="c-info text-start">
                                    <h4>Telefon</h4>
                                    <p>(+48) 602 190 277</p>
                                </div>
                            </div>
                        </a>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default ContactUs;
