import React, { useState, useEffect } from "react";
import Doctor from "../../../components/Doctor/Doctor";
import Feature from "../../../components/Feature/Feature";
// import LatestBlog from "../../../components/LatestBlog/LatestBlog";
import Service from "../../../components/Service/Service";
import About from "../About/About";
import Promo from "../Appoinment-promo/Promo";
// import Appoinment from "../Appoinment/Appoinment";
import HomeCarousel from "../Banner/Banner";
import Gallery from "../Gallery/Gallery";
import Slick from "../Slick/Slick";
import Testimonial from "../Testimonial/Testimonial";
import axios from "axios";

const Home = () => {
    const [ambulatoryServices, setAmbulatoryServices] = useState([]);
    const [aestheticyServices, setAestheticyServices] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                // setLoading(true);
                const res = await axios.get("/api/services/");
                const ambulatory = res.data.filter(
                    (service) => service.category === "surgery"
                );
                setAmbulatoryServices(ambulatory);

                const aesthetic = res.data.filter(
                    (service) => service.category === "aesthetic"
                );
                setAestheticyServices(aesthetic);
            } catch (err) {
                // showErrorMessage(err);
            } finally {
                // setLoading(false);
            }
        };
        fetchData();
    }, []);

    return (
        <>
            <HomeCarousel />
            <Feature />
            <About />
            <Service
                title={"Zabiegi Chirurgii Ambulatoryjnej"}
                services={ambulatoryServices}
            />
            <Service
                title={"Zabiegi Medycyny Estetycznej"}
                services={aestheticyServices}
            />
            <Doctor />
            {/* <Appoinment /> */}
            <Gallery />
            <Testimonial />
            {/* <LatestBlog /> */}
            <Promo />
            <Slick />
        </>
    );
};

export default Home;
