import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./About.css";

const About = () => {
    useEffect(() => {
        AOS.init({
            duration: 2000,
        });
        AOS.refresh();
    }, []);
    return (
        <section className="about-wrapper">
            <Container>
                <Row className="align-items-center">
                    <Col md={12} lg={6}>
                        <div className="about-left">
                            <img
                                src={"/static/about-banner1.png"}
                                alt="donto"
                                className="img-fluid donto"
                            />

                            <img
                                src={"/static/kijek.png"}
                                alt="donto"
                                className="img-fluid animated dontoAnim"
                            />

                            <img
                                src={"/static/cleaner.png"}
                                alt="donto"
                                className="img-fluid animated dontoAnim"
                            />
                        </div>
                    </Col>
                    <Col md={12} lg={6}>
                        <div className="about-right mt-5 mt-lg-0">
                            <div
                                className="about-content text-start"
                                data-aos="zoom-in"
                            >
                                <h1>Witaj w PM Gabinety</h1>
                                <p>
                                    Witaj na stronie PM Gabinety. Zapoznaj się z
                                    naszą ofertą. Z przyjemnością odpowiemy na
                                    wszelkie pytania. Umów się już dziś!
                                </p>
                                <a href="/about">O nas</a>
                            </div>
                            <div className="fun-fact-sec" data-aos="fade-right">
                                <div className="single-fun">
                                    <span>250</span>
                                    <span>+</span>
                                    <p>Zadowolonych pacjentów</p>
                                </div>
                                <div
                                    className="single-fun sp-fun"
                                    data-aos="fade-right"
                                >
                                    <span>Zabiegi</span>
                                    <p>na certyfikowanym sprzęcie</p>
                                </div>
                                <div
                                    className="single-fun"
                                    data-aos="fade-left"
                                >
                                    <span>32</span>
                                    <span>+</span>
                                    <p>Lata doświadczenia</p>
                                </div>
                                <div
                                    className="single-fun sp-fun"
                                    data-aos="fade-left"
                                >
                                    <span>15</span>
                                    <span>+</span>
                                    <p>Cetryfikatów</p>
                                </div>
                                <span className="line"></span>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default About;
