import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
    selectOpen,
    selectSeverity,
    selectMessage,
    hideAlert,
} from "src/redux/slices/AlertSlice";
import "./GlobalAlert.css";

const GlobalAlert = ({ duration }) => {
    const open = useSelector(selectOpen);
    const severity = useSelector(selectSeverity);
    const message = useSelector(selectMessage);
    const dispatch = useDispatch();

    useEffect(() => {
        // When the component mounts, set up a timer to automatically hide the alert
        if (open) {
            const timer = setTimeout(() => {
                // Dispatch the hideAlert action to close the alert
                dispatch(hideAlert());
            }, duration);

            // Clear the timer when the component unmounts or when the alert is manually closed
            return () => {
                clearTimeout(timer);
            };
        }
    }, [open, duration, dispatch]);

    return (
        <div className={`global-alert ${open ? "show" : ""}`}>
            <Alert variant={severity} show={open}>
                <div className="alert-content">{message}</div>
            </Alert>
        </div>
    );
};

export default GlobalAlert;
