// import { faUser } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Container, Nav, Navbar, NavDropdown, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Header.css";

// import { useSelector, useDispatch } from "react-redux";
// import { logout } from "redux/slices/Auth";

const Header = () => {
    // const { isAuthenticated, loading, user } = useSelector(
    //     (state) => state.auth
    // );

    return (
        <div className="head-bg">
            <Container>
                <Row>
                    <Col className="d-flex justify-content-start">
                        <a href="/home">
                            <img
                                src={"/static/pm_gabinety_logo.png"}
                                width="400px"
                                alt="logo"
                            />
                        </a>
                    </Col>
                    <Col className="d-flex justify-content-end">
                        <Navbar
                            className="custom-navbar"
                            collapseOnSelect
                            expand="lg"
                        >
                            <Container className="container-head">
                                <Navbar.Toggle
                                    aria-controls="basic-navbar-nav"
                                    expand="lg"
                                />
                                <Navbar.Collapse id="basic-navbar-nav">
                                    <Nav className="ms-auto align-items-center">
                                        <Link
                                            to="/home"
                                            className="list-item text-decoration-none"
                                        >
                                            Strona główna
                                        </Link>
                                        <NavDropdown
                                            className="list-item text-decoration-none custom-nav-dropdown"
                                            title="Usługi"
                                        >
                                            <NavDropdown.Item
                                                href="/service/surgery"
                                                className="list-item text-decoration-none"
                                            >
                                                Chirurgia ogólna
                                            </NavDropdown.Item>
                                            <NavDropdown.Item
                                                href="/service/aesthetic"
                                                className="list-item text-decoration-none"
                                            >
                                                Medycyna estetyczna
                                            </NavDropdown.Item>
                                        </NavDropdown>

                                        {/* <Link
                                            to="/dentist"
                                            className="list-item text-decoration-none"
                                        >
                                            Lekarze
                                        </Link> */}
                                        <Link
                                            to="/about"
                                            className="list-item text-decoration-none"
                                        >
                                            O nas
                                        </Link>
                                        <Link
                                            to="/contact"
                                            className="list-item text-decoration-none"
                                        >
                                            Kontakt
                                        </Link>
                                        {/* {user && user.email ? (
                                            <button
                                                type="button"
                                                className="btn btn-danger"
                                                onClick={logout}
                                            >
                                                Log Out
                                            </button>
                                        ) : (
                                            <Link
                                                to="/login"
                                                type="button"
                                                className="btn btn-danger"
                                            >
                                                Zaloguj się
                                            </Link>
                                        )}
                                        {user && user.email && (
                                            <Navbar.Text>
                                                <FontAwesomeIcon
                                                    icon={faUser}
                                                />
                                                <span className="userName">
                                                    {user.displayName}
                                                </span>
                                            </Navbar.Text>
                                        )} */}
                                    </Nav>
                                </Navbar.Collapse>
                            </Container>
                        </Navbar>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default Header;
