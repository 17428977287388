import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Detail.css";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";

import AOS from "aos";
import "aos/dist/aos.css";

const Detail = ({ type }) => {
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await axios.get(`/api/services/category/${type}`);
                setServices(res.data);
            } catch (err) {
                // showErrorMessage(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();

        AOS.init({
            duration: 2000,
        });
        AOS.refresh();
    }, [type]);

    useEffect(() => {
        const scrollToSection = () => {
            const href = window.location.href.substring(
                window.location.href.lastIndexOf("#") + 1
            );
            const element = document.getElementById(href);
            if (element) {
                element.scrollIntoView({ behavior: "smooth" });
            }
        };
        if (!loading && services.length > 0) {
            scrollToSection();
        }
    }, [services, loading]);

    const renderText = (service, prop) => {
        return (
            <div className="expertDentist-txt mt-5 mt-lg-0">
                {service.customfields.map((customField, index) => (
                    <>
                        <h3
                            className={"fw-bold " + prop ? prop : ""}
                            data-aos="flip-right"
                            key={customField.name}
                        >
                            {customField.heading}
                        </h3>
                        {customField.texts.map((text) => (
                            <p
                                className={prop ? prop : ""}
                                data-aos="flip-left"
                                key={text.id}
                            >
                                {text.content}
                            </p>
                        ))}
                    </>
                ))}
            </div>
        );
    };

    const renderImage = (image, prop) => {
        return (
            <img
                src={`${axios.defaults.baseURL}${image}`}
                alt=""
                className={"img-fluid " + prop ? prop : ""}
                data-aos="flip-left"
            />
        );
    };

    return (
        <>
            {loading || services.length === 0 ? (
                <Loader />
            ) : (
                <>
                    {services.map((item, index) => (
                        <section
                            className={
                                "healing p-5 " +
                                (index % 2 === 1 ? "footer-bg" : "")
                            }
                            id={item.id}
                            key={item.id}
                        >
                            <Container>
                                <div className="expertDentist-txt mt-5 mt-lg-0">
                                    <h2
                                        className={
                                            "fw-bold " +
                                            (index % 2 === 1
                                                ? "text-white"
                                                : "")
                                        }
                                        data-aos="flip-right"
                                    >
                                        {item.name}
                                    </h2>

                                    <p
                                        className={
                                            "" +
                                            (index % 2 === 1
                                                ? "text-white"
                                                : "")
                                        }
                                        data-aos="flip-left"
                                    >
                                        {item.description}
                                    </p>
                                </div>
                                <Row className="align-items-center p-5">
                                    {index % 2 === 1 ? (
                                        <>
                                            <Col>
                                                {renderText(item, "text-white")}
                                            </Col>
                                            <Col>
                                                {renderImage(
                                                    item.image,
                                                    "pt-xs-5"
                                                )}
                                            </Col>
                                        </>
                                    ) : (
                                        <>
                                            <Col>{renderImage(item.image)}</Col>
                                            <Col>{renderText(item)}</Col>
                                        </>
                                    )}
                                </Row>
                            </Container>
                        </section>
                    ))}
                </>
            )}
        </>
    );
};

export default Detail;
