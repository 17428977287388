import React from "react";
import { Col, Container, NavLink, Row } from "react-bootstrap";
import "./Footer.css";

const Footer = () => {
    return (
        <div className="footer-bg">
            <Container>
                <Row className="text-white justify-content-md-center">
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Skróty</h2>
                            </div>
                            <div className="widget-content">
                                {/* <NavLink className="footer-link">FAQ's</NavLink> */}
                                <NavLink className="footer-link" href="/about">
                                    O nas
                                </NavLink>
                                <NavLink
                                    className="footer-link"
                                    href="/contact"
                                >
                                    Kontakt
                                </NavLink>
                                <NavLink className="footer-link">Blog</NavLink>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Usługi</h2>
                            </div>
                            <div className="widget-content">
                                <NavLink
                                    className="footer-link"
                                    href="/service/surgery"
                                >
                                    Porady i zabiegi chirurgiczne ambulatoryjne
                                </NavLink>
                                <NavLink
                                    className="footer-link"
                                    href="/service/aesthetic"
                                >
                                    Porady i zabiegi medycyny estetycznej
                                </NavLink>
                            </div>
                        </div>
                    </Col>
                    <Col xs={6} md={3}>
                        <div className="single-footer-widget">
                            <div className="widget-title">
                                <h2>Nasz adres</h2>
                            </div>
                            <div className="widget-content">
                                <NavLink className="footer-link">
                                    PM Gabinety Paweł Mizerny
                                </NavLink>
                                <NavLink className="footer-link">
                                    ul. Plac Ratuszowy 1
                                </NavLink>
                                <NavLink className="footer-link">
                                    72-500 Międzyzdroje
                                </NavLink>
                                <NavLink className="footer-link">
                                    Tel: 602 73 92 93
                                </NavLink>
                                <NavLink className="footer-link">
                                    Email: p.mizerny@wp.pl
                                </NavLink>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <div className="footer-copy-right text-center text-white">
                <p className="mb-0">
                    &copy; {new Date().getFullYear()} -{" "}
                    <span className="developer">Mayersoft Michal Majeran</span>{" "}
                    | Wszelkie Prawa Zastrzeżone
                </p>
                <p className="mb-0">
                    App icons by{" "}
                    <a href="https://icons8.com/" className="link-primary">
                        icons8
                    </a>
                </p>
            </div>
        </div>
    );
};

export default Footer;
