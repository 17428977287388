import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Promo.css";

const Promo = () => {
    return (
        <section className="promo-wrapper">
            <Container>
                <Row className="align-items-center">
                    <Col md={12} sm={12} lg={6}>
                        <div className="promo-content text-white text-start">
                            <h1 className="mt-sm-req">
                                Umów się na wizytę już dziś!
                            </h1>
                            <Link to="/contact#appointment">
                                <button
                                    href=".#"
                                    className="theme-btn btn-fill mt-4"
                                >
                                    Umów wizytę
                                </button>
                            </Link>
                        </div>
                    </Col>
                    <Col md={12} sm={12} lg={6}>
                        <div className="promo-banner">
                            <img src={"/static/doctor-nurse.png"} alt="" />
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Promo;
