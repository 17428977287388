import {
    faFacebook,
    faLinkedin,
    faTwitter,
    faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";

export const getSocialMediaIcon = (socialMedia) => {
    let icon;

    switch (socialMedia) {
        case "facebook":
            icon = faFacebook;
            break;
        case "twitter":
            icon = faTwitter;
            break;
        case "linkedin":
            icon = faLinkedin;
            break;
        case "whatsapp":
            icon = faWhatsapp;
            break;
        default:
            icon = null;
            break;
    }

    return icon;
};
