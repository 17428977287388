import "./App.css";
// import { useState } from "react";
import { Outlet } from "react-router-dom";

//
import Header from "./Header/Header";
import Footer from "./Footer/Footer";
// import Alert from "src/utils/Alert";
// import { useDispatch, useSelector } from "react-redux";

// ----------------------------------------------------------------------

// const APP_BAR_MOBILE = 64;
// const APP_BAR_DESKTOP = 92;

// const RootStyle = styled("div")({
//     display: "flex",
//     minHeight: "100%",
//     overflow: "hidden",
// });

// const MainStyle = styled("div")(({ theme }) => ({
//     flexGrow: 1,
//     overflow: "auto",
//     minHeight: "100%",
//     paddingTop: APP_BAR_MOBILE + 24,
//     paddingBottom: theme.spacing(10),
//     [theme.breakpoints.up("lg")]: {
//         paddingTop: APP_BAR_DESKTOP + 24,
//         paddingLeft: theme.spacing(2),
//         paddingRight: theme.spacing(2),
//     },
// }));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
    // const dispatch = useDispatch();

    return (
        <div className="App">
            <Header />
            <Outlet />
            <Footer />
        </div>
    );
}
