import React from "react";

import "./Banner.css";

const Aesthetic = () => {
    return (
        <div className="hero-slide-right text-start">
            <div className="banner-animate">
                <img
                    src={"/static/services/aesthetic/hero-main-bg.png"}
                    alt=""
                    className="img-fluid"
                />
                <img
                    src={"/static/services/aesthetic/ludzik1.png"}
                    alt=""
                    className="img-fluid a5"
                />
                <img
                    src={"/static/services/aesthetic/ludzik2.png"}
                    alt=""
                    className="img-fluid a6"
                />
                <img
                    src={"/static/services/aesthetic/ludzik3.png"}
                    alt=""
                    className="img-fluid a2"
                />
                <img
                    src={"/static/services/aesthetic/ludzik4.png"}
                    alt=""
                    className="img-fluid a2"
                />
                <img
                    src={"/static/services/aesthetic/ludzik5.png"}
                    alt=""
                    className="img-fluid a7"
                />
            </div>
        </div>
    );
};

export default Aesthetic;
