import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import "./Services.css";
import axios from "axios";

const Services = (props) => {
    const { name, shortDescription, thumbnail, category, id } = props.treatment;
    useEffect(() => {
        AOS.init({
            duration: 2000,
        });
        AOS.refresh();
    }, []);

    return (
        <>
            <Col md={6} lg={6} xl={4} xs={12} style={{ marginBottom: "30px" }}>
                <div className="single-service-box" data-aos="flip-left">
                    {/* <Stack gap={3} className="col-md-5 mx-auto"> */}
                    <div className="service-icon">
                        <img
                            src={`${axios.defaults.baseURL}${thumbnail}`}
                            alt=""
                        />
                    </div>
                    <h3>{name}</h3>
                    <p>{shortDescription}</p>
                    <a href={`/service/${category}#${id}`}>Więcej</a>
                    {/* </Stack> */}
                </div>
            </Col>
        </>
    );
};

export default Services;
