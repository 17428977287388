import { configureStore } from "@reduxjs/toolkit";
import alertReducer from "./slices/AlertSlice";
import authReducer from "./slices/Auth";

export default configureStore({
    reducer: {
        alert: alertReducer,
        auth: authReducer,
    },
    devTools: true,
});
