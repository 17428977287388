import "@fontsource/josefin-sans";
import { Col, Container, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import HomeCarousel from "./HomeCarousel";
import "./Banner.css";

const Banner = () => {
    return (
        <section className="single-hero-slide text-white d-flex justify-content-center align-items-center">
            <Container>
                <Row className="align-items-center">
                    <Col md={12} sm={12} lg={6}>
                        <div className="hero-slide-left justify-content-end align-items-center text-center text-lg-start">
                            <h2>Piękny i zdrowy wygląd dzięki</h2>
                            <h1>medycynie estetycznej</h1>
                            <p className="mb-xs-5">
                                Zapraszamy do naszych gabinetów. Również Ty
                                możesz wyglądać pięknie i zdrowo już dziś.
                            </p>
                            <div className="banner-btn m-sm-auto">
                                <Link to="/contact#appointment">
                                    <button className="theme-btn btn-fill">
                                        Umów wizytę
                                    </button>
                                </Link>
                                <button className="theme-btn bth-blank">
                                    Dowiedz się więcej
                                </button>
                            </div>
                        </div>
                    </Col>
                    <Col md={12} sm={12} lg={6}>
                        <HomeCarousel />
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Banner;
