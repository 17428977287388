import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import "./Details.css";
import { getSocialMediaIcon } from "../../../Data/Social";
import Loader from "../../../components/Loader/Loader";
import axios from "axios";

const Details = () => {
    const [doctors, setDoctors] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const res = await axios.get("/api/doctors/");
                setDoctors(res.data);
            } catch (err) {
                // showErrorMessage(err);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    return (
        <>
            {loading ? (
                <Loader />
            ) : (
                <>
                    {doctors.length > 0 && (
                        <>
                            {doctors.map((doctor) => (
                                <section
                                    className="dentist-details-sec"
                                    key={doctor.id}
                                >
                                    <Container>
                                        <Row>
                                            <Col md={7} lg={8}>
                                                <div className="single-dentist-details">
                                                    <h2>
                                                        {doctor.name}
                                                        {/* <span>
                                                            (DMD, MS, DICOI)
                                                        </span> */}
                                                    </h2>
                                                    <p>{doctor.description}</p>
                                                </div>
                                            </Col>
                                            <Col md={5} lg={4}>
                                                <div className="dentist-profile text-center">
                                                    <div className="profile-img">
                                                        <img
                                                            src={doctor.image}
                                                            alt={`Zdjęcie ${doctor.name}`}
                                                        />
                                                    </div>
                                                    <p>
                                                        Imię:{" "}
                                                        <strong>
                                                            {doctor.name}
                                                        </strong>
                                                    </p>

                                                    {doctor.specializations.filter(
                                                        (spec) =>
                                                            spec.name !== null
                                                    ).length > 0 && (
                                                        <p>
                                                            Specjalizacja:{" "}
                                                            <strong>
                                                                {doctor.specializations
                                                                    .filter(
                                                                        (
                                                                            spec
                                                                        ) =>
                                                                            spec.name !==
                                                                            null
                                                                    )
                                                                    .map(
                                                                        (
                                                                            spec
                                                                        ) =>
                                                                            spec.name
                                                                    )
                                                                    .reduce(
                                                                        (
                                                                            prev,
                                                                            curr
                                                                        ) => [
                                                                            prev,
                                                                            ", ",
                                                                            curr,
                                                                        ]
                                                                    )}
                                                            </strong>
                                                        </p>
                                                    )}

                                                    {doctor.phone && (
                                                        <p>
                                                            Telefon:{" "}
                                                            <strong>
                                                                {doctor.phone}
                                                            </strong>
                                                        </p>
                                                    )}
                                                    {doctor.socialmedias.filter(
                                                        (social) =>
                                                            social.url !== null
                                                    ).length > 0 && (
                                                        <div
                                                            className="doctors-social"
                                                            data-aos="flip-left"
                                                        >
                                                            {doctor.socialmedias
                                                                .filter(
                                                                    (social) =>
                                                                        social.url !==
                                                                        null
                                                                )
                                                                .map(
                                                                    (
                                                                        social
                                                                    ) => {
                                                                        return (
                                                                            <a
                                                                                href={
                                                                                    social.url
                                                                                }
                                                                                key={
                                                                                    social.id
                                                                                }
                                                                            >
                                                                                <FontAwesomeIcon
                                                                                    icon={getSocialMediaIcon(
                                                                                        social.social
                                                                                    )}
                                                                                />
                                                                            </a>
                                                                        );
                                                                    }
                                                                )}
                                                        </div>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Container>
                                </section>
                            ))}
                        </>
                    )}
                </>
            )}
        </>
    );
};

export default Details;
