// routes
import Router from "./routes";

// redux store
import Store from "./redux/Store";
import { Provider } from "react-redux";
import GlobalAlert from "./utils/GlobalAlert";
import axios from "axios";

if (window.location.origin.includes("localhost")) {
    axios.defaults.baseURL = "http://127.0.0.1:8000";
} else {
    axios.defaults.baseURL = window.location.origin;
}

function App() {
    return (
        <Provider store={Store}>
            <GlobalAlert duration={5000} />
            <Router />
        </Provider>
    );
}

export default App;
