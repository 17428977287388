import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import "./Appoinment.css";
import { useDispatch } from "react-redux";
import { showAlert } from "src/redux/slices/AlertSlice";
import { post } from "src/services/content.service";

const validationSchema = Yup.object().shape({
    name: Yup.string().required("Pole Imię jest wymagane"),
    email: Yup.string()
        .email("Niepoprawny format email")
        .required("Pole Email jest wymagane"),
    phone: Yup.string().required("Pole Telefon jest wymagane"),
    subject: Yup.string().required("Pole Temat jest wymagane"),
    message: Yup.string().required("Pole Wiadomość jest wymagane"),
});

const Appoinment = () => {
    const dispatch = useDispatch();
    const initialValues = {
        name: "",
        email: "",
        phone: "",
        subject: "",
        message: "",
    };

    const handleSubmit = async (values, { setSubmitting, resetForm }) => {
        try {
            // setLoading(true);
            await post(dispatch, `/api/utils/email`, values);
            dispatch(
                showAlert({
                    message: "Formularz został wysłany pomyślnie.",
                    severity: "primary",
                })
            );
            resetForm();
        } catch (err) {
            dispatch(
                showAlert({
                    message: "Wystąpił błąd podczas przetwarzania formularza.",
                    severity: "danger",
                })
            );
        } finally {
            setSubmitting(false);
        }
    };

    return (
        <section className="appoinment-wrapper">
            <Container>
                <Row>
                    <Col sm={12} md={12}>
                        <div className="section-title">
                            <h1 className="mt-5">Umów wizytę</h1>
                        </div>
                        <div className="appoinment-form">
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={handleSubmit}
                            >
                                {({ isSubmitting }) => (
                                    <Form>
                                        <Row>
                                            <Col md={6} lg={6}>
                                                <Field
                                                    type="text"
                                                    name="name"
                                                    placeholder="Imię"
                                                />
                                                <ErrorMessage
                                                    name="name"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Field
                                                    type="email"
                                                    name="email"
                                                    placeholder="Email"
                                                />
                                                <ErrorMessage
                                                    name="email"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Field
                                                    type="text"
                                                    name="phone"
                                                    placeholder="Telefon"
                                                />
                                                <ErrorMessage
                                                    name="phone"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </Col>
                                            <Col md={6} lg={6}>
                                                <Field
                                                    type="text"
                                                    name="subject"
                                                    placeholder="Temat"
                                                />
                                                <ErrorMessage
                                                    name="subject"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </Col>
                                            <Col md={12} lg={12}>
                                                <Field
                                                    as="textarea"
                                                    name="message"
                                                    id="message"
                                                    cols="30"
                                                    rows="10"
                                                    placeholder="Wiadomość"
                                                />
                                                <ErrorMessage
                                                    name="message"
                                                    component="div"
                                                    className="error-message"
                                                />
                                            </Col>
                                        </Row>
                                        <button
                                            type="submit"
                                            className="theme-btn btn-fill form-btn mt-5"
                                            disabled={isSubmitting}
                                        >
                                            {isSubmitting
                                                ? "Wysyłanie..."
                                                : "Wyślij"}
                                        </button>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};

export default Appoinment;
