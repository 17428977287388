import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AOS from "aos";
import "aos/dist/aos.css";
import React, { useEffect } from "react";
import { Col } from "react-bootstrap";
import "./Doctors.css";
import { getSocialMediaIcon } from "../../../Data/Social";

const Doctors = (props) => {
    const { name, shortDescription, thumbnail, socialmedias } = props.doctor;
    useEffect(() => {
        AOS.init({
            duration: 2000,
        });
        AOS.refresh();
    }, []);

    return (
        <Col md={6} lg={6} xl={4} xs={12}>
            <div className="single-feature-box sigle-doctor">
                <div className="doctors-profile" data-aos="fade-down">
                    <img src={thumbnail} alt="" />
                </div>
                <div className="doctors-info" data-aos="fade-left">
                    <h3 className="mb-0">
                        <a href=".#">{name}</a>
                    </h3>
                    <span>{shortDescription}</span>
                </div>

                {socialmedias.filter((social) => social.url !== null).length >
                    0 && (
                    <div className="doctors-social" data-aos="flip-left">
                        {socialmedias
                            .filter((social) => social.url !== null)
                            .map((social) => {
                                return (
                                    <a href={social.url} key={social.id}>
                                        <FontAwesomeIcon
                                            icon={getSocialMediaIcon(
                                                social.social
                                            )}
                                        />
                                    </a>
                                );
                            })}
                    </div>
                )}
            </div>
        </Col>
    );
};

export default Doctors;
